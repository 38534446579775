/* Camiones */
.transBox {
    background: none repeat scroll 0 0 #f7f7f7;
    padding: 13px;
    position: relative;
}
.transBox .camion {
    width: 793px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    zoom: 0.70;
}
.transBox .tabs {
    text-align: center;
}
.transBox .tabs li {
    margin-bottom: 10px;
    display: inline-block;
    width: auto;
}
.transBox .tab_content > div {
    height: 192px;
    margin: 50px auto 20px;
    position: relative;
    width: 791px;
}
.transBox .tabs li.active a {
    background: none repeat scroll 0 0 #27b36a;
}
.transBox .camionA {
    /*background: url("../images/camionA.jpg") no-repeat scroll left top rgba(0, 0, 0, 0);*/
}
.transBox .camionB {
    /*background: url("../images/camionB.jpg") no-repeat scroll left top rgba(0, 0, 0, 0);*/
}
.transBox .camionC {
    /*background: url("../images/camionC.jpg") no-repeat scroll left top rgba(0, 0, 0, 0);*/
}
.transBox .camionD {
    /*background: url("../images/camionD.jpg") no-repeat scroll left top rgba(0, 0, 0, 0);*/
}
.transBox .asiento {
    color: #585858;
    cursor: pointer;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 14px;
    height: 31px;
    line-height: 31px;
    position: absolute;
    text-align: center;
    width: 33px;
}
.transBox .asiento:hover, .asiento.active {
    background-color: #27b36a;
    color: #ffffff;
}
.asiento.booked {
    background-color: #a9a9a9 !important;
    color: #ffffff;
    cursor: not-allowed;
}
.camionA .a1 {
    bottom: 11px;
    left: 130px;
}
.camionA .a2 {
    bottom: 48px;
    left: 130px;
}
.camionA .a3 {
    left: 121px;
    top: 45px;
}
.camionA .a4 {
    left: 121px;
    top: 9px;
}
.camionA .a5 {
    bottom: 11px;
    left: 180px;
}
.camionA .a6 {
    bottom: 48px;
    left: 180px;
}
.camionA .a7 {
    left: 171px;
    top: 45px;
}
.camionA .a8 {
    left: 171px;
    top: 9px;
}
.camionA .a9 {
    bottom: 11px;
    left: 242px;
}
.camionA .a10 {
    bottom: 48px;
    left: 242px;
}
.camionA .a11 {
    left: 223px;
    top: 45px;
}
.camionA .a12 {
    left: 223px;
    top: 9px;
}
.camionA .a13 {
    bottom: 11px;
    left: 293px;
}
.camionA .a14 {
    bottom: 48px;
    left: 293px;
}
.camionA .a15 {
    left: 274px;
    top: 45px;
}
.camionA .a16 {
    left: 274px;
    top: 9px;
}
.camionA .a17 {
    bottom: 11px;
    left: 349px;
}
.camionA .a18 {
    bottom: 48px;
    left: 349px;
}
.camionA .a19 {
    left: 325px;
    top: 45px;
}
.camionA .a20 {
    left: 325px;
    top: 9px;
}
.camionA .a21 {
    bottom: 11px;
    left: 404px;
}
.camionA .a22 {
    bottom: 48px;
    left: 404px;
}
.camionA .a23 {
    bottom: 11px;
    right: 298px;
}
.camionA .a24 {
    bottom: 48px;
    right: 298px;
}
.camionA .a25 {
    right: 263px;
    top: 45px;
}
.camionA .a26 {
    right: 263px;
    top: 9px;
}
.camionA .a27 {
    bottom: 11px;
    right: 244px;
}
.camionA .a28 {
    bottom: 48px;
    right: 244px;
}
.camionA .a29 {
    right: 217px;
    top: 45px;
}
.camionA .a30 {
    right: 217px;
    top: 9px;
}
.camionA .a31 {
    bottom: 11px;
    right: 189px;
}
.camionA .a32 {
    bottom: 48px;
    right: 189px;
}
.camionA .a33 {
    right: 170px;
    top: 45px;
}
.camionA .a34 {
    right: 170px;
    top: 9px;
}
.camionA .a35 {
    bottom: 11px;
    right: 137px;
}
.camionA .a36 {
    bottom: 48px;
    right: 137px;
}
.camionA .a37 {
    right: 123px;
    top: 45px;
}
.camionA .a38 {
    right: 123px;
    top: 9px;
}
.camionA .a39 {
    bottom: 11px;
    right: 83px;
}
.camionA .a40 {
    bottom: 48px;
    right: 83px;
}
.camionA .a41 {
    right: 76px;
    top: 45px;
}
.camionA .a42 {
    right: 76px;
    top: 9px;
}
.camionA .a43 {
    bottom: 11px;
    right: 29px;
}
.camionA .a44 {
    bottom: 48px;
    right: 29px;
}
.camionA .a45 {
    right: 29px;
    top: 45px;
}
.camionA .a46 {
    right: 29px;
    top: 9px;
}
.camionB .a1 {
    bottom: 11px;
    left: 114px;
}
.camionB .a2 {
    bottom: 47px;
    left: 114px;
}
.camionB .a3 {
    left: 122px;
    top: 46px;
}
.camionB .a4 {
    left: 122px;
    top: 9px;
}
.camionB .a5 {
    bottom: 11px;
    left: 165px;
}
.camionB .a6 {
    bottom: 47px;
    left: 165px;
}
.camionB .a7 {
    left: 173px;
    top: 46px;
}
.camionB .a8 {
    left: 173px;
    top: 9px;
}
.camionB .a9 {
    bottom: 11px;
    left: 216px;
}
.camionB .a10 {
    bottom: 47px;
    left: 216px;
}
.camionB .a11 {
    left: 225px;
    top: 46px;
}
.camionB .a12 {
    left: 225px;
    top: 9px;
}
.camionB .a13 {
    bottom: 11px;
    left: 267px;
}
.camionB .a14 {
    bottom: 47px;
    left: 267px;
}
.camionB .a15 {
    left: 277px;
    top: 46px;
}
.camionB .a16 {
    left: 277px;
    top: 9px;
}
.camionB .a17 {
    bottom: 11px;
    left: 318px;
}
.camionB .a18 {
    bottom: 47px;
    left: 318px;
}
.camionB .a19 {
    left: 329px;
    top: 46px;
}
.camionB .a20 {
    left: 329px;
    top: 9px;
}
.camionB .a21 {
    bottom: 11px;
    left: 368px;
}
.camionB .a22 {
    bottom: 47px;
    left: 368px;
}
.camionB .a23 {
    bottom: 11px;
    left: 425px;
}
.camionB .a24 {
    bottom: 47px;
    left: 425px;
}
.camionB .a25 {
    bottom: 11px;
    left: 476px;
}
.camionB .a26 {
    bottom: 47px;
    left: 476px;
}
.camionB .a27 {
    bottom: 11px;
    right: 231px;
}
.camionB .a28 {
    bottom: 47px;
    right: 231px;
}
.camionB .a29 {
    right: 224px;
    top: 46px;
}
.camionB .a30 {
    right: 224px;
    top: 9px;
}
.camionB .a31 {
    bottom: 11px;
    right: 179px;
}
.camionB .a32 {
    bottom: 47px;
    right: 179px;
}
.camionB .a33 {
    right: 176px;
    top: 46px;
}
.camionB .a34 {
    right: 176px;
    top: 9px;
}
.camionB .a35 {
    bottom: 11px;
    right: 130px;
}
.camionB .a36 {
    bottom: 47px;
    right: 130px;
}
.camionB .a37 {
    right: 127px;
    top: 46px;
}
.camionB .a38 {
    right: 127px;
    top: 9px;
}
.camionB .a39 {
    bottom: 11px;
    right: 79px;
}
.camionB .a40 {
    bottom: 47px;
    right: 79px;
}
.camionB .a41 {
    right: 79px;
    top: 46px;
}
.camionB .a42 {
    right: 79px;
    top: 9px;
}
.camionB .a43 {
    bottom: 15px;
    right: 30px;
}
.camionB .a44 {
    bottom: 49px;
    right: 30px;
}
.camionB .a45 {
    bottom: 84px;
    right: 30px;
}
.camionB .a46 {
    right: 30px;
    top: 42px;
}
.camionB .a47 {
    right: 30px;
    top: 9px;
}
.camionC .a1 {
    bottom: 11px;
    left: 259px;
}
.camionC .a2 {
    bottom: 48px;
    left: 259px;
}
.camionC .a3 {
    bottom: 85px;
    left: 259px;
}
.camionC .a4 {
    left: 315px;
    top: 9px;
}
.camionC .a5 {
    bottom: 11px;
    left: 324px;
}
.camionC .a6 {
    bottom: 48px;
    left: 324px;
}
.camionC .a7 {
    left: 379px;
    top: 9px;
}
.camionC .a8 {
    bottom: 11px;
    left: 385px;
}
.camionC .a9 {
    bottom: 48px;
    left: 385px;
}
.camionC .a10 {
    left: 441px;
    top: 9px;
}
.camionC .a11 {
    bottom: 11px;
    left: 449px;
}
.camionC .a12 {
    bottom: 48px;
    left: 449px;
}
.camionC .a13 {
    left: 504px;
    top: 9px;
}
.camionC .a14 {
    bottom: 11px;
    left: 511px;
}
.camionC .a15 {
    bottom: 48px;
    left: 511px;
}
.camionC .a16 {
    bottom: 11px;
    right: 182px;
}
.camionC .a17 {
    bottom: 48px;
    right: 182px;
}
.camionC .a18 {
    right: 182px;
    top: 45px;
}
.camionC .a19 {
    right: 182px;
    top: 9px;
}
.camionC .a20 {
    left: 175px;
    top: 29px;
}
.camionD .a1 {
    bottom: 13px;
    left: 128px;
}
.camionD .a2 {
    bottom: 49px;
    left: 128px;
}
.camionD .a3 {
    left: 120px;
    top: 45px;
}
.camionD .a4 {
    left: 120px;
    top: 9px;
}
.camionD .a5 {
    bottom: 13px;
    left: 179px;
}
.camionD .a6 {
    bottom: 49px;
    left: 179px;
}
.camionD .a7 {
    left: 169px;
    top: 45px;
}
.camionD .a8 {
    left: 169px;
    top: 9px;
}
.camionD .a9 {
    bottom: 13px;
    left: 231px;
}
.camionD .a10 {
    bottom: 49px;
    left: 231px;
}
.camionD .a11 {
    left: 223px;
    top: 45px;
}
.camionD .a12 {
    left: 223px;
    top: 9px;
}
.camionD .a13 {
    bottom: 13px;
    left: 284px;
}
.camionD .a14 {
    bottom: 49px;
    left: 284px;
}
.camionD .a15 {
    left: 280px;
    top: 45px;
}
.camionD .a16 {
    left: 280px;
    top: 9px;
}
.camionD .a17 {
    bottom: 13px;
    left: 341px;
}
.camionD .a18 {
    bottom: 49px;
    left: 341px;
}
.camionD .a19 {
    left: 337px;
    top: 45px;
}
.camionD .a20 {
    left: 337px;
    top: 9px;
}
.camionD .a21 {
    bottom: 13px;
    left: 398px;
}
.camionD .a22 {
    bottom: 49px;
    left: 398px;
}
.camionD .a23 {
    left: 391px;
    top: 45px;
}
.camionD .a24 {
    left: 391px;
    top: 9px;
}
.camionD .a25 {
    bottom: 13px;
    right: 303px;
}
.camionD .a26 {
    bottom: 49px;
    right: 303px;
}
.camionD .a27 {
    right: 313px;
    top: 45px;
}
.camionD .a28 {
    right: 313px;
    top: 9px;
}
.camionD .a29 {
    bottom: 13px;
    right: 249px;
}
.camionD .a30 {
    bottom: 49px;
    right: 249px;
}
.camionD .a31 {
    right: 255px;
    top: 45px;
}
.camionD .a32 {
    right: 255px;
    top: 9px;
}
.camionD .a33 {
    bottom: 13px;
    right: 195px;
}
.camionD .a34 {
    bottom: 49px;
    right: 195px;
}
.camionD .a35 {
    right: 199px;
    top: 45px;
}
.camionD .a36 {
    right: 199px;
    top: 9px;
}
.camionD .a37 {
    bottom: 13px;
    right: 143px;
}
.camionD .a38 {
    bottom: 49px;
    right: 143px;
}
.camionD .a39 {
    right: 146px;
    top: 45px;
}
.camionD .a40 {
    right: 146px;
    top: 9px;
}
.camionD .a41 {
    bottom: 13px;
    right: 90px;
}
.camionD .a42 {
    bottom: 49px;
    right: 90px;
}
.camionD .a43 {
    right: 91px;
    top: 45px;
}
.camionD .a44 {
    right: 91px;
    top: 9px;
}
.camionD .a45 {
    bottom: 13px;
    right: 36px;
}
.camionD .a46 {
    bottom: 49px;
    right: 36px;
}

/* Camión 5 */
.camionE .a1 {bottom: 11px;left: 113px;}
.camionE .a2{bottom: 47px;left: 113px;}
.camionE .a3{bottom: 115px;left: 91px;}
.camionE .a4{bottom: 151px;left: 91px;}
.camionE .a5{bottom: 11px;left: 164px;}
.camionE .a6{bottom: 47px;left: 164px;}
.camionE .a7{bottom: 114px;left: 140px;}
.camionE .a8{bottom: 150px;left: 140px;}
.camionE .a9{bottom: 11px;left: 215px;}
.camionE .a10{bottom: 47px;left: 215px;}
.camionE .a11{bottom: 114px;left: 191px;}
.camionE .a12{bottom: 150px;left: 191px;}
.camionE .a13{bottom: 11px;left: 266px;}
.camionE .a14{bottom: 47px;left: 266px;}
.camionE .a15{bottom: 114px;left: 242px;}
.camionE .a16{bottom: 150px;left: 242px;}
.camionE .a17{bottom: 11px;left: 316px;}
.camionE .a18{bottom: 47px;left: 316px;}
.camionE .a19{bottom: 114px;left: 292px;}
.camionE .a20{bottom: 150px;left: 292px;}
.camionE .a21{bottom: 11px;left: 367px;}
.camionE .a22{bottom: 47px;left: 367px;}
.camionE .a23{bottom: 11px;left: 424px;}
.camionE .a24{bottom: 47px;left: 424px;}
.camionE .a25{bottom: 114px;left: 423px;}
.camionE .a26{bottom: 150px;left: 423px;}
.camionE .a27{bottom: 11px;left: 475px;}
.camionE .a28{bottom: 47px;left: 475px;}
.camionE .a29{bottom: 114px;left: 475px;}
.camionE .a30{bottom: 150px;left:475px;}
.camionE .a31{bottom: 11px;left: 526px;}
.camionE .a32{bottom: 47px;left: 526px;}
.camionE .a33{bottom: 114px;left: 526px;}
.camionE .a34{bottom: 150px;left: 526px;}
.camionE .a35{bottom: 11px;left: 576px;}
.camionE .a36{bottom: 47px;left: 576px;}
.camionE .a37{bottom: 114px;left: 576px;}
.camionE .a38{bottom: 150px;left: 576px;}
.camionE .a39{bottom: 11px;left: 626px;}
.camionE .a40{bottom: 47px;left: 626px;}
.camionE .a41{bottom: 114px;left: 626px;}
.camionE .a42{bottom: 150px;left: 626px;}
.camionE .a43{bottom: 11px;left: 678px;}
.camionE .a44{bottom: 47px;left: 678px;}
.camionE .a45{bottom: 114px;left: 678px;}
.camionE .a46{bottom: 150px;left: 678px;}
.camionE .a47{bottom: 10px;left: 727px;}
.camionE .a48{bottom: 46px;left: 727px;}
.camionE .a49{bottom: 80px;left: 727px;}
.camionE .a50{bottom: 115px;left: 727px;}
.camionE .a51{bottom: 150px;left: 727px;}


.stdBtn {
    display: inline-block;
    background: #a9a9a9;
    border: medium none !important;
    border-radius: 8px;
    color: #fff;
    height: 30px;
    line-height: 30px;
    padding: 0 12px;
    text-decoration: none;
}
.stdBtn:hover {
    background-color: #45d58b;
}

.customer-seat li{
    display: inline-block;
    width: 200px;
    vertical-align: top !important;
    margin-right: 50px;
}


@media print {
    footer, header, #right-nav, .img-holder, #profile h1, .buttons, .exp-col{display: none !important;}
    #profile .card-approved{display: none;}
}