
/**********************************************************
	Chat
**********************************************************/
.chat-tab{}
.chat-tab legend{
	display: block;
	border: none;
	width: 100%;
	background-color: #3E3E3E;
	color: #fff;
	font-size: 14px;
	padding: 5px 10px;
	font-weight: bold;
}
.chat-tab a{color: #9540E2;}
.chat-tab .menu {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 50px;
    background: rgba(82,179,217,0.9);
    z-index: 100;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.chat-tab .back {
    position: absolute;
    width: 90px;
    height: 50px;
    top: 0px;
    left: 0px;
    color: #fff;
    line-height: 50px;
    font-size: 30px;
    padding-left: 10px;
    cursor: pointer;
}
.chat-tab .back img {
    position: absolute;
    top: 5px;
    left: 30px;
    width: 40px;
    height: 40px;
    background-color: rgba(255,255,255,0.98);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    margin-left: 15px;
    }
.chat-tab .back:active {
    background: rgba(255,255,255,0.2);
}
.chat-tab .name{
    position: absolute;
    top: 3px;
    left: 110px;
    font-family: 'Lato';
    font-size: 25px;
    font-weight: 300;
    color: rgba(255,255,255,0.98);
    cursor: default;
}
.chat-tab .last{
    position: absolute;
    top: 30px;
    left: 115px;
    font-family: 'Lato';
    font-size: 11px;
    font-weight: 400;
    color: rgba(255,255,255,0.6);
    cursor: default;
}
/* M E S S A G E S */
.chat-tab .chat {
    list-style: none;
    background: none;
    margin: 0;
    margin-bottom: 10px;
    height: 380px;
    max-height: 380px; 
    overflow-y: auto;
}
.chat-tab .chat li {
    padding: 0.5rem;
    overflow: hidden;
    display: flex;
}
.chat-tab .chat .avatar {
    width: 40px;
    height: 40px;
    position: relative;
    display: block;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    background-color: rgba(255,255,255,0.9);
}
.chat-tab .chat .avatar img {
    width: 35px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    background-color: rgba(255,255,255,0.9);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.chat-tab .chat .day {
    position: relative;
    display: block;
    text-align: center;
    color: #c0c0c0;
    height: 20px;
    text-shadow: 7px 0px 0px #e5e5e5, 6px 0px 0px #e5e5e5, 5px 0px 0px #e5e5e5, 4px 0px 0px #e5e5e5, 3px 0px 0px #e5e5e5, 2px 0px 0px #e5e5e5, 1px 0px 0px #e5e5e5, 1px 0px 0px #e5e5e5, 0px 0px 0px #e5e5e5, -1px 0px 0px #e5e5e5, -2px 0px 0px #e5e5e5, -3px 0px 0px #e5e5e5, -4px 0px 0px #e5e5e5, -5px 0px 0px #e5e5e5, -6px 0px 0px #e5e5e5, -7px 0px 0px #e5e5e5;
    box-shadow: inset 20px 0px 0px #e5e5e5, inset -20px 0px 0px #e5e5e5, inset 0px -2px 0px #d7d7d7;
    line-height: 38px;
    margin-top: 5px;
    margin-bottom: 20px;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.chat-tab .other .msg {
    order: 1;
    border-top-left-radius: 0px;
    box-shadow: -1px 2px 0px #D4D4D4;
}
.chat-tab .other:before {
    content: "";
    position: relative;
    top: 0px;
    right: 0px;
    left: 40px;
    width: 0px;
    height: 0px;
    border: 5px solid #E1E2E3;
    border-left-color: transparent;
    border-bottom-color: transparent;
}
.chat-tab .self {
    justify-content: flex-end;
    align-items: flex-end;
}
.chat-tab .self .msg {
    order: 1;
    border-bottom-right-radius: 0px;
    box-shadow: 1px 2px 0px #D4D4D4;
}
.chat-tab .self .avatar {
    order: 2;
}
.chat-tab .self .avatar:after {
    content: "";
    position: relative;
    display: inline-block;
    bottom: 19px;
    right: 0px;
    width: 0px;
    height: 0px;
    border: 5px solid #E1E2E3;
    border-right-color: transparent;
    border-top-color: transparent;
    box-shadow: 0px 2px 0px #D4D4D4;
}
.chat-tab .msg {
    background: #E1E2E3;
    min-width: 50px;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.07);
}
.chat-tab .msg p {
    font-size: 0.8rem;
    margin: 0 0 0.2rem 0;
    color: #777;
}
.chat-tab .msg img {
    position: relative;
    display: block;
    width: 450px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px #eee;
    transition: all .4s cubic-bezier(0.565, -0.260, 0.255, 1.410);
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
@media screen and (max-width: 800px) {
    .chat-tab .msg img {width: 300px;}
}
@media screen and (max-width: 550px) {
    .chat-tab .msg img {width: 200px;}
}
.chat-tab .msg time {
    font-size: 0.7rem;
    color: #000;
    margin-top: 3px;
    float: right;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.chat-tab .msg time:before{
    content:"\f017";
    color: #ddd;
    font-family: FontAwesome;
    display: inline-block;
    margin-right: 4px;
}

@-webikt-keyframes pulse {
  from { opacity: 0; }
  to { opacity: 0.5; }
}


/*
::-webkit-scrollbar {
    min-width: 12px;
    width: 12px;
    max-width: 12px;
    min-height: 12px;
    height: 12px;
    max-height: 12px;
    background: #e5e5e5;
    box-shadow: inset 0px 50px 0px rgba(82,179,217,0.9), inset 0px -52px 0px #fafafa;
}

::-webkit-scrollbar-thumb {
    background: #bbb;
    border: none;
    border-radius: 100px;
    border: solid 3px #e5e5e5;
    box-shadow: inset 0px 0px 3px #999;
}

::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
    box-shadow: inset 0px 0px 3px #888;
}

::-webkit-scrollbar-thumb:active {
    background: #aaa;
  box-shadow: inset 0px 0px 3px #7f7f7f;
}

::-webkit-scrollbar-button {
    display: block;
    height: 26px;
}
*/
.chat-tab .empty-chat{
    font-size: 45px;
    text-align: center;
    color: #F2F2F2;
    height: 100px;
    line-height: 100px;
    vertical-align: middle;
    position: relative;
    margin-bottom: 30px;
}
.chat-tab .empty-chat::after{
    display: block;
    position: absolute;
    top: 10px;
    left: 0px;
    width: 100%;
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
    color: #CCC;
    font-size: 14px;
    font-weight: 100;
    display: block;
    text-align: center;
    content: 'No hay mensajes';
    margin-bottom: 8px;
}
.chat-tab textarea{
    position: inherit;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 50px;
    z-index: 99;
    background: #fafafa;
    border: none;
    outline: none;
    padding: 15px 15px;
    color: #666;
    height: 100px;
    resize: none;
    border: 1px solid #EDEDED;
    font-size: 14px;
}



.btn-chat{position: relative;}
.btn-chat .bubble{
    position: absolute;
    width: 20px; 
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    background-color: #fff200;
    color: #00a651;
    font-size: 9px;
    top: -10px;
    right: -10px;
    border-radius: 50%;
}