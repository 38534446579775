:root{
	--mit-yellow:#f0ea03;
	--mit-green:#00a550;
	--mit-deep-green:#00381b;
	--mit-mid-green:#0e873e;
	--mit-light-green:#18a951;
	--mit-gray:#949494;
	--mit-dark:#111111;
	--mit-space-top:240px;
	--mit-bg-gray:#f1f1f1;
	--mit-std-section-padding:36px 0px 130px 0px;
}
textarea:focus, input:focus, button:focus{outline: none;}
body{
	display: block;
	margin: 0;
	padding: 0;
	font-family: 'Oxygen', sans-serif;
	width: 100%;
	max-width: 100% !important;
	overflow-x: hidden;
	min-width: 320px !important;
}
h1,h2,h3,h4,h5,ul,p,a{
	display: block;
	margin: 0px;
	list-style: none;
	text-decoration: none;
	padding: 0px;
}
.breakpoint{
	display: block;
	margin: 0 auto;
	min-width: 320px;
}
.progress{
	color: var(--mit-green);
	margin-top: 80px;
}
.hidden{display: none !important;}

.whatsapp-plugin{
    position: fixed;
    z-index: 2;
    bottom: 50px;
    left: 20px;
    height: 60px;
    min-width: 60px;
    max-width: 95vw;
    background-color: #25d366;
    color: #fff;
    border-radius: 30px;
    box-shadow: 1px 6px 24px 0 rgba(7,94,84,.24);
    cursor: pointer;
    transition: background-color .2s linear;
    text-align: center;
    line-height: 58px;
    vertical-align: middle;
    font-size: 36px;
}

/* CTA's */
.cta{
	display: inline-block;
	height: 44px;
	width: auto;
	padding: 0px 16px;
	line-height: 44px;
	border: none;
	border: 1px solid #fff;
	text-align: center;
	color: #fff;
	font-size: 16px;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	transition: all 0.45s;
	cursor: pointer;
}
.cta i{font-size: 16px !important;}
.cta.green{background-color: var(--mit-green);}
.cta.green:hover, .cta.green.active{background-color: var(--mit-yellow); color: var(--mit-dark) !important;}
.cta.gray{background-color: var(--mit-gray);}
.cta.gray:hover, .cta.gray.active{background-color: var(--mit-dark)}
.cta.white{background-color: #fff; color: var(--mit-green) !important;}
.cta.white:hover, .cta.white.active{background-color: var(--mit-yellow); color: var(--mit-dark) !important;}
.cta.yellow{background-color: var(--mit-yellow); color: var(--mit-dark) !important;}
.cta.yellow:hover, .cta.yellow.active{background-color: #000; color: #fff !important;}
.cta.red{background-color:transparent; border:1px solid red; color:red; padding: 0px 10px !important;}
.cta.black{background-color: var(--mit-dark); color: #fff !important;}
.cta.black:hover, .cta.black.active{background-color: #45d58b; color: #fff !important;}
.link-more{
	color: var(--mit-green);
	font-weight: bold;
	font-size: 16px;
	cursor: pointer;
	background-color: transparent;
	border:none;
}

/* Titles */
h1{
	font-size: 60px;
	color: #b0b0b0;
	font-weight: 600;
	margin-bottom: 36px;
}

/* Labesl with Checkboxes */
label.has-check{position: relative; display:block; cursor: pointer; padding-left: 30px; line-height: 16px; }
label.has-check input[type="checkbox"]{
	width:0px;
	height:0px;
	margin: 0px;
	padding: 0px;
	position: absolute;
	top:0px;
	left: 0px;
	bottom: 18px;
	display: inline-block;
	opacity: 0;
}
label.has-check::after{
	display: block;
	position: absolute;
	height: 16px;
	width: 16px;
	content:' ';
	background-color: #fff;
	border: 1px solid #C2C2C2;
	top: 0px;
	left: 0px;
	z-index: 0;
}
label.has-check.checked::before{
	display: block;
	position: absolute;
	height: 12px;
	width: 12px;
	content:' ';
	background-color: #0d873e;
	left: 3px;
	top:17px !important;
	z-index: 1;
}
.resume-box label.has-check{margin-top: 15px;}
.resume-box label.has-check.checked::before{top: 3px !important;}
label.has-check.disabled{
	cursor: not-allowed;
	opacity: 0.4;
}



/* HEADER */
header{
	display: block;
	max-width: 100%;;
	width: 100%;
	min-width: 320px;
	padding: 28px 0px;
	color: #000;
	font-size: 14px;
	height: auto;
	z-index: 1;
}
header a{color:#000; text-decoration: none;}
header .logo{
	width: 100%;
	height: 100%;
	text-align: center;
}
header .logo img{
	display: inline-block;
	width: 100px;
}
header .tel{color: #000; height: 24px; line-height: 24px; vertical-align: middle;}
header .tel b{color: #000; /*var(--mit-yellow);*/ font-weight: normal;}
header .follow-us{text-align: right; display: flex;  justify-content: flex-end; gap: 10px;}
header .follow-us ul{display: inline-block;}
header .follow-us ul li{display: inline-flex;}
header .follow-us span{height: 24px; line-height: 24px; vertical-align: middle;}
header .follow-us ul li a{color:#fff; text-align: center; padding: 0px 8px; transition: all 0.3s; height: 24px; line-height: 24px; vertical-align: middle; display: flex; flex-direction: row;}
header .follow-us ul li a i{ font-size: 20px;}
header .follow-us ul li:nth-child(1) a{
	color: #3b5998;
}
header .follow-us ul li:nth-child(2) a{
	color: #00acee;
}
header .follow-us ul li:nth-child(3) a{
	color: #E1306C;
}
header .follow-us ul li:nth-child(4) a{
	color: #128C7E;
}

header .follow-us ul li a:hover{color: var(--mit-yellow);}
header nav{margin-top: 50px;}
header nav ul li{display: inline-block; margin-right: 22px;}
header nav ul li a{
	display: block;
	color: #000;
	text-align: left;
	padding: 0px;
	height: 50px;
	line-height: 50px;
    transition: all 0.3s;
    font-size: 16px;
	font-weight: 600;
}
header nav ul li a:hover,header nav ul li a.active{
	color: var(--mit-mid-green);
	text-decoration: underline;
}
header .cta{margin-top: 50px; width:100%; cursor: pointer; padding: 0px; background-color: var(--mit-light-green);}
header .cta:hover{
	background-color: var(--mit-yellow);
	color: var(--mit-dark);
}
header .cta.red{margin-top:0px; width: auto; padding: 0px 15px;}


/* Header para móviles */
.header-mobile .logo{display: inline-block;}
.header-mobile .logo img{width: 100%;}
.header-mobile .menu-trigger{
	display: inline-block;
	padding: 7px;
	font-size: 22px;
	border:3px solid #fff;
	background-color: transparent;
	color: var(--mit-green); /*#128C7E;*/
	cursor: pointer;
}
.header-mobile .user-options{
  	width: 94%;
  	background-color: rgba(0, 56, 27, 0.95);
	border-radius: 4px;
	padding: 15px 3%;
	position: relative;
	margin-top:30px;
}
.header-mobile .user-options small{display: block; color: #fff;}
.header-mobile .user-options big{
	display: block;
	font-size: 24px;
	line-height: 34px;
	font-weight: bold;
	max-height: 100%;
	overflow: hidden;
	color: #FFF;
}
.header-mobile .user-options span{
	display: inline-block;
	position: absolute;
	height: 30px;
	line-height: 30px;
	vertical-align: middle;
	text-align: right;
	top: 50%;
	margin-top: -15px;
	right: 0px;
	color: #fff;
}
.header-mobile .user-options span i{
	display: inline-block;
	color:var(--mit-yellow);
	margin-left: 10px;
	margin-top: -5px;
	height: 30px;
	line-height: 27px;
	vertical-align: middle;
	margin-right: 15px;
}
.header-mobile .user-options nav{
	position: absolute;
	top: 100%;
	margin-top: -0px;
	left: 0px;
  	width: 94%;
  	background-color: rgba(0, 56, 27, 0.95);
  	z-index: 2;
  	padding: 15px 3%;
  	border-bottom-right-radius: 4px;
  	border-bottom-left-radius: 4px;
}
.header-mobile .user-options nav .mitem{
	display: block;
	text-align: right;
	color: #fff;
	text-decoration: none;
	height: 35px;
	line-height: 35px;
	cursor: pointer;
	text-transform: uppercase;
	background-color: transparent;
	width: 100%;
	border:none;
}
.header-mobile .user-options nav .mitem:hover,
.header-mobile .user-options nav .mitem.active{color: var(--mit-yellow) !important;}
.header-mobile .user-options nav .mitem b{
	display:inline-block;
	color:var(--mit-yellow);
}
.header-mobile .mobile-menu{
	position: absolute;
	background-color: rgba(0, 56, 27, 0.95);
	z-index: 9999;
	width: 94%;
	padding: 15px 3%;
	top:104px;
	left: 0px;
	text-align: right;
}
.header-mobile .mobile-menu nav{margin-top:20px; margin-bottom: 30px;}
.header-mobile .mobile-menu a.mail,
.header-mobile .mobile-menu a.tel{text-align: right; padding-right: 22px; color:#FFF; text-decoration: none;}
.header-mobile .mobile-menu a.tel{margin-top: 10px; margin-bottom:50px;}
.header-mobile .mobile-menu li{display: block;}
.header-mobile .mobile-menu li a{text-align: right; color: #fff;}
.header-mobile .follow-us{margin-bottom:50px; padding-right: 22px;}
.header-mobile .follow-us span{display: block;}
.header-mobile .follow-us ul li{display: inline-block; margin-right: 15px;}
.header-mobile .follow-us ul li.last{margin-right: 0px;}
.header-mobile  .follow-us ul li a{padding: 0px;}
.header-mobile .cta{margin-top: 0px; width: 200px; margin-bottom: 40px; margin-right: 22px;}


/* Agenda */
#agenda{ padding:var(--mit-std-section-padding);}
#agenda.destino h1{margin-bottom: 0px;}
#agenda.destino h3{
	color: #b0b0b0;
	margin-bottom: 60px;
}

/* BANNERS */
.banner-holder .banner-item{
    position: relative;
    min-height: 500px;
    /*background-image: url("http://new.mar-iguanatours.com.mx/files/banners_home/Huasteca Hidalguense 10.jpg");*/
    background-size: cover;
    background-position: center center;
}
.banner-holder .banner-item img{
}
.banner-holder .banner-item .banner-text{
	position: absolute;
	width: 1140px;
	height: 250px;
	top: 200px;
	left:50%;
	margin-left: -570px;
	z-index: 3;
	text-align: center;
}
.banner-holder .banner-item .banner-text h2{
	color: #fff;
	text-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
	font-size: 44px;
	vertical-align: middle;
	font-weight: 600;
}
.banner-holder .banner-item .banner-text span{
	display: block;
	font-size: 16px;
	color: #fff;
	text-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
}
.banner-holder .banner-item .banner-text h3{
	font-size: 22px;
	font-weight: 100;
	color: var(--mit-yellow);
	margin-bottom: 25px;
	text-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
}
.banner-holder .control-dots{bottom: 80px !important;}
.banner-holder .control-dots .dot{
	width: 22px !important;
	height: 22px !important;
	background-color: transparent !important;
	border:2px solid #FFF !important;
	opacity: 1 !important;
	transition: all 0.35s !important;
}
.banner-holder .control-dots .dot:hover{
	background-color: rgba(255,255,255,0.3) !important;
}
.banner-holder .control-dots .dot.selected{
	background-color: #FFF !important;
}


/* HOME */
#home #agenda{background-color: var(--mit-bg-gray); margin-top: 0px;}
#home #blog{margin-top: 0px;}


/* Detalle */
#detalle{background-color: var(--mit-bg-gray);}
#detalle #blog{margin-top: 0px;}
#detalle .boxed{background-color: #fff; padding: 32px; margin-bottom: 32px;}
#detalle .boxed h2{
	font-size: 12px;
	color: #bcbcbc;
	margin-bottom: 38px;
	font-weight: 300;
}
#detalle .progress{margin-top: 200px;}
#detalle .boxed article{font-size: 14px; margin-bottom: 55px;}
#detalle .boxed article:last-child{margin-bottom: 0px;}
#detalle .boxed article b{display:block; color: var(--mit-green); margin-bottom: 14px;}
#detalle .boxed article p{display: block; color:#757575; font-weight: bold; margin-bottom: 12px;}
#detalle .boxed article span{color:#bcbcbc; display: block; margin-bottom: 18px;}
#detalle .boxed article span b{color:#ff0404; display: inline-block;}
#detalle .boxed.green{background-color: var(--mit-green);}
#detalle .boxed.green h2{color:#fff; margin-bottom: 14px;}
#detalle .boxed.green big{
	font-size: 32px;
	font-weight: 300;
	color: #fff;
	display: block;
	margin-bottom: 12px;
}
#detalle .detail-holder{padding: 60px 0px;}
#detalle .detail-holder .detail-text{color: #757575; overflow-wrap: break-word;}
#detalle .banner-holder .banner-item .banner-text{padding-left: 100px; padding-right: 100px;}


/* Blog */
#blog{ padding:var(--mit-std-section-padding); background-color: #fff;}
#blog .link-more{margin-top: 40px; display: block; text-align: right;}


/* Blog Post */
#blog-post{ padding:var(--mit-std-section-padding); background-color: #fff; padding-bottom: 0px;}
#blog-post .gray-container{background-color:#f1f1f1; padding: 80px 0px;}
#blog-post .gray-container .img-holder img{width: 100%;}
#blog-post .gray-container .breakpoint{background-color: #fff;}
#blog-post .gray-container h2{margin-top: 50px; color: var(--mit-green); font-size: 22px; margin-bottom: 15px;}
#blog-post .gray-container span{display:block; font-size: 18px; color: var(--mit-gray); margin-bottom: 50px;}
#blog-post .gray-container .post-text{
	display: block;
	margin-bottom: 80px;
}
#blog-post .gray-container .post-text ul,
#blog-post .gray-container .post-text ol{padding-left:18px; display: block; margin:0px; margin-top: 30px;}
#blog-post .gray-container .post-text ul li,
#blog-post .gray-container .post-text ol li{margin-bottom: 15px;}
#blog-post .gray-container .post-text p{
	width: 100%;
	max-width: 100%;
	word-wrap: break-word;
	text-align: justify;
	margin-bottom: 30px;
}
#blog-post .gray-container .post-text strong,
#blog-post .gray-container .post-text b{
	font-weight: bold !important;
	color:#000;
}
#blog-post #blog{margin: 0px; padding-bottom: 40px;}
#blog-post #blog .link-more, #blog-post #blog h1{display: none;}


/* Event Items */
.event-item{margin-bottom: 30px; background-color: #fff; font-size: 16px;}
.event-item .img-holder{
	width: 100%;
	overflow:hidden;
	height: 100%;
	background-size: cover;
	min-height: 300px;
}
.event-item img{max-width: 100%;}
.event-item h2{
	color: var(--mit-green);
	font-size: 26px;
	font-weight: 600;
	margin-top: 20px;
	margin-bottom: 5px;
}
.event-item label{
	color: var(--mit-green);
}
.event-item b{color: #111111;}
.event-item span{color: var(--mit-gray);}
.event-item span b{color: #ff0000;}
.event-item p{color: var(--mit-gray); margin-top: 16px; margin-bottom: 24px;}
.event-item .text{padding: 0px 54px;}
.event-item .text .pack{margin-top:10px; padding-top: 10px; font-size: 14px; border-top:1px solid var(--mit-bg-gray);}
.event-item .text .pack b{
	display: block;
	color: #ff0404;
}
.event-item .text .pack h3{
	font-size: 14px;
	font-weight: 100;
	color: #111111;
}
.event-item .button-holder{margin-bottom: 30px;}
.event-item .button-holder .cta.green{margin-right: 15px;}


/* NUESTROS DESTINOS */
#nuestros-destinos{ padding:var(--mit-std-section-padding);}


/* Blog Items */
.blog-item .img-holder{
	width: 100%;
	height: 180px;
	overflow: hidden;
}
.blog-item .img-holder img{
	display: block;
	width: 100%;
}
.blog-item h2{
	display: block;
	margin-top: 21px;
	color: var(--mit-green);
	margin-bottom: 16px;
	font-size: 18px;
}
.blog-item label{
	display: block;
	color: var(--mit-gray);
	font-size: 16px;
	margin-bottom: 5px;
}
.blog-item small{
	display: block;
	color: var(--mit-dark);
	font-size: 12px;
}
.blog-item .cta{margin-bottom: 45px;}



/* Conócenos */
#conocenos{padding: 0px 0px 100px 0px;}
#conocenos .img-holder img{width: 100%;}
#conocenos h2{
	font-size: 22px;
	color: var(--mit-green);
	margin-bottom: 24px;
}
#conocenos p{
	font-size: 16px;
	color: var(--mit-gray);
	margin-bottom: 44px;
	text-align: justify;
}


/* Nuestros Destinos */
#nuestros-destinos{padding-bottom: 100px;}


/* Contacto */
#contacto{ padding:var(--mit-std-section-padding);}
#contacto p{color: #868686; font-size: 16px; margin-bottom: 40px;}
#contacto a{
	font-size: 20px;
	font-weight: bold;
	color: var(--mit-green);
	margin-bottom: 5px;
}
#contacto .mit-forms{margin-top: 80px !important;}



/* Forms */
.mit-forms{margin-top: 80px;}
.mit-forms label{display:block; color: #868686; font-size: 16px; margin-bottom: 18px;}
.mit-forms input[type="text"],
.mit-forms input[type="password"],
.mit-forms textarea,
.mit-forms select{
	height: 42px;
	border:1px solid #d2d2d2;
	background-color: #f2f2f2;
	padding: 0px 4%;
	color: #868686;
	font-size: 16px;
	border-radius: 6px;
	margin-bottom: 24px;
	width: 92%;
	font-family: 'Oxygen', sans-serif;
}
.mit-forms input[type="text"]::placeholder,
.mit-forms input[type="password"]::placeholder,
.mit-forms textarea::placeholder,
.mit-forms select::placeholder{
	color:#DDD;
}

.mit-forms select{width: 100%;}
.mit-forms .simulate-field{
	line-height: 16px !important;
	vertical-align: middle;
	border:1px solid #d2d2d2;
	background-color: #f2f2f2;
	padding: 0px 12%;
	color: #868686;
	font-size: 16px;
	border-radius: 6px;
	margin-bottom: 24px;
	width:76%;
}
.mit-forms .simulate-field small{
	display: block;
	font-size: 12px;
	font-weight: 100;
	height: 18px;
	line-height: 18px;
	margin-top: 5px;
	color: var(--mit-dark);
	margin-bottom: 2px;
	margin-left: -20px;
}
.mit-forms .simulate-field small i{
	display: inline-block;
	margin-right: 0px;
	font-weight: normal;
	text-align: center;
	width: 20px;
}

.mit-forms textarea{height: 100px; resize: none; padding: 8px 4%;}
.mit-forms h2{
	color: var(--mit-green);
	margin-bottom: 28px;
	font-size: 22px;
}
.mit-forms p, .mit-forms b{
	display: block;
	color: var(--mit-dark);
	margin-bottom: 20px;
	font-size: 14px;
}
 .mit-forms b{
 	color: red;
	font-weight: 100;
 }
.mit-forms .divider{
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
}
.mit-forms .divider::before{
	display: block;
	content: ' ';
	background-color: #EDEDED;
	position: absolute;
	height: 100%;
	width: 1px;
	left: 50%;
	top: 0px;
}
.mit-forms .divider::after{
	display: block;
	content: 'ó';
	position: absolute;
	width: 50px;
	height: 50px;
	line-height: 50px;
	color: var(--mit-green);
	top: 50%;
	margin-top: -25px;
	left: 50%;
	margin-left: -25px;
	text-align: center;
	font-size: 26px;
	background-color: #fff;
}




/* Acceso */
#acceso{ padding:var(--mit-std-section-padding);}
#acceso h2 svg{
	font-size: 32px;
	color: var(--mit-dark);
	text-align: center;
	margin: 0 auto;
	width: 50px;
	margin: 0 auto;
}
#acceso h1{margin-bottom: 0px;}
#acceso .mit-forms{margin-top: 50px !important;}



/* Event Holder */
.event-holder{
    padding: 27px 12px;
    border: 1px solid #c8c8c8;
	position: relative;
	margin-bottom: 40px;
}
.event-holder .exp-col{
	position: absolute;
	right: -1px;
	top: -1px;
	width: 22px;
	height: 23px;
	line-height: 23px;
	vertical-align: middle;
	border: 1px solid #c8c8c8;
	cursor: pointer;
	text-align: center;
	color: #c8c8c8;
	font-size: 16px;
	transition: all 0.35s;
}
.event-holder .exp-col:hover{color: var(--mit-dark);}
.event-holder .exp-col.close{background-position: right top;}
.event-holder h2{
	color: #585858;
	font-size: 36px;
	font-weight: 100;
	margin-bottom: 35px;
}
.event-holder hr{
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 0px;
    margin-bottom: 20px;
    padding: 0;
}
.event-holder section{
	padding: 10px;
	overflow: hidden;
}
.event-holder .top-bar{padding-top: 20px;}
.event-holder .disclaimer{color: red; margin-top: 10px; display: block;}
/* Preview */
.event-holder .preview{background-color: #f7f7f7; margin-bottom:30px;}
.event-holder .preview .img-holder{display: block; overflow: hidden;}
.event-holder .preview .img-holder img{width: 100%;}
.event-holder .preview h3{
	font-size: 26px;
	font-weight: 600;
	color: var(--mit-green);
	margin-bottom: 5px;
}
.event-holder .preview .period span{color: var(--mit-green);}
.event-holder .preview .code{margin-top: 10px;}
.event-holder .preview .code label{color: var(--mit-dark); display: block; margin-bottom: 5px;}
.event-holder .preview .code span{
	display: block;
	margin-bottom: 15px;
	font-size: 14px;
	color: var(--mit-gray);
}
.event-holder .preview .more ul li{
	display: block;
	line-height: 30px;
}
.event-holder .preview .more ul li i{display: inline-block;margin-right: 7px;}
.event-holder .preview .more ul li span{font-size: 12px;}
.event-holder .preview .chart{background-color:#e9fff2;}
/* Botones */
.event-holder .buttons{margin-bottom: 17px;}
/* Depositos */
.event-holder .detail .deposits{background-color: #f7f7f7; margin-bottom: 15px; padding-bottom: 40px;}
.event-holder .detail .deposits .row{border-bottom: 1px solid #c6c6c6; color: #585858; margin-bottom: 5px;}
.event-holder .detail .deposits .row span{
	display: block;
	height: 50px;
	line-height: 50px;
}
.event-holder .detail .deposits .row .id{color: var(--mit-green); font-weight: bold; padding-left: 10px;}
.event-holder .detail .deposits .row .status{font-weight: bold;}
/* Total */
.event-holder .detail .total{background-color: #fff; padding-right: 30px;}
.event-holder .detail .total span{
	display: inline-block;
	height: 50px;
	line-height: 50px;
	vertical-align: middle;
}
.event-holder .detail .total b{
	display: inline-block;
	height: 50px;
	line-height: 50px;
	vertical-align: middle;
	color: var(--mit-green);
}
.event-holder .seats{background-color: #f7f7f7;}

/* Modals */
.mit-dialog{
	border-radius: 10px;
	text-align: center;
}
.mit-dialog div{overflow: hidden !important;}
.mit-dialog h2{
	color: var(--mit-dark);
	margin-bottom: 0px;
}
.mit-dialog i{
	display: inline-block;
	margin-bottom: 20px;
	font-size: 40px;
}
.mit-dialog.success i{color: var(--mit-green);}
.mit-dialog p{color: var(--mit-gray);font-weight: 300;}
.mit-dialog{justify-content:center; padding-bottom: 15px;}
.mit-dialog .mit-forms{margin-top: 30px !important;}
.mit-dialog{justify-content:center; padding-bottom: 15px;}
.mit-dialog .mit-forms form input[type="text"]{margin-top: 30px; margin-bottom: 0px;}
.mit-dialog .mit-forms p{margin:0px;}
.mit-dialog .dialog-actions{text-align: center; justify-content:center; margin-bottom:20px;}


/* FOOTER */
footer{
	background-color: var(--mit-green);
	padding-top: 90px;
	color: #fff;
}
footer h2{
	font-size: 28px;
	font-weight:100;
	margin-bottom: 44px;
}
footer p, footer a{
	font-size: 16px;
	color: #fff;
	text-decoration: none;
	font-weight: 100;
	transition: all 0.45s;
}
footer a:hover{color: var(--mit-yellow);}
footer p{margin-bottom: 30px;}
footer a{margin-bottom: 10px;}
footer ul li a{
	display: block;
	line-height: 28px;
	transition: all 0.45s;
}
footer ul li a span{
	display: inline-block;
	width: 30px;
	height: 28px;
	vertical-align: middle;
	line-height: 28px;
	text-align: left;
}
footer .bottom{
	margin-top: 48px;
	background-color: var(--mit-green); /*  var(--mit-deep-green); */
	padding: 30px 0px;
	text-align: center;
}
footer .bottom img{
	height: 120px;
}





/**************************************************
	PROFILE CSS
**************************************************/
#profile h1{color: #585858; font-weight: 100; font-size: 36px; height: 36px; line-height: 36px; vertical-align: middle;}
#profile header.desktop{
	background-color: var(--mit-dark);
	height: 50px;
	padding: 0px;
	background-image: none;
	position: relative;
}
#profile header.desktop label{
	display: block;
	height: 50px;
	line-height: 50px;
	color: #fff;
	text-align: center;
	background-color:var(--mit-mid-green);
}
#profile header.desktop label i{
	color: var(--mit-yellow);
	font-size: 18px;
	margin-right: 5px;
}
#profile header.desktop .mitem{
	display: inline-block;
	height: 50px;
	line-height: 50px;
	vertical-align: middle;
	color: var(--mit-light-green);
	margin-right: 20px;
	transition: all 0.3s;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: none;
}
#profile header.desktop .mitem span{
	display: block;
	width: 16px;
	height: 16px;
	line-height: 16px;
	border-radius: 100%;
	background-color: var(--mit-green);
	color: #FFF;
	font-size: 10px;
	text-align: center;
	font-weight: 100;
	position: absolute;
	top:5px;
	right: -15px;
	transition: all 0.3s;
}
#profile header.desktop .mitem:hover span{
	background-color: var(--mit-yellow);
	color: #111111;
}
#profile header.desktop .mitem i{color: #fff; margin-right:5px;}
#profile header.desktop .mitem.active, #profile header.desktop .mitem:hover{color: #fff;}
#profile header.desktop .mitem.active i, #profile header.desktop .mitem:hover i{color: var(--mit-yellow);}
#profile a.log-out{margin-right: 0px;}
#profile a.log-out i{color: red; margin-right: 5px;}
#profile #right-nav{background-color: var(--mit-light-green); padding: 35px 0px 100px 0px;}
#profile #right-nav #logo{text-align: center; margin-bottom: 50px;}
#profile #right-nav ul{
	background-color: var(--mit-mid-green);
	padding: 25px 0px;
}
#profile #right-nav ul li a{
	color: #fff;
	padding: 0px 25px;
	height: 40px;
	line-height: 40px;
	vertical-align: middle;
	transition: all 0.3s;
	font-size: 16px; font-weight: 100;
}
#profile #right-nav ul li a:hover,
#profile #right-nav ul li a.active{
	background-color: var(--mit-bg-gray);
	color: #585858;
}
#profile #right-nav ul li a i{
	display: inline-block;
	width: 40px;
	text-align: center;
}
#profile #right-nav ul li a:hover i{color:var(--mit-green);}
#profile #right-nav ul li a span{
	color: var(--mit-yellow);
	font-weight: 100;
}
#profile #right-nav ul li a.active span{color:var(--mit-green);}
#profile main{padding: 30px 0px 30px 60px;}

/* Cards Notifications */
#profile div small{font-weight: bold; font-size: 12px;}
#profile div p{font-size: 14px; font-style: italic;}
#profile div label{font-size: 12px; display: block; margin-bottom: 10px;}
#profile div .media{
	width: 100%;
    height: 100px;
    background-size: 90%;
    background-position: center;
    padding-top: 50px;
}
#profile div[class*="card-"] div[class*="MuiCardActions-"]{justify-content: flex-end;}
#profile .card-info{border:1px solid #b8daff; background-color: #cce5ff;}
#profile .card-info h2{color: #004085;}
#profile .card-info p,
#profile .card-info label{color:#004085;}
#profile .card-waiting{border:1px solid #ffc600; background-color: #feffe1;}
#profile .card-waiting h2{color: #7b6413;}
#profile .card-waiting p,
#profile .card-waiting label{color:#6f5a12;}
#profile .card-approved{border:1px solid #398C18; background-color: #E7F7ED;}
#profile .card-approved h2{color: var(--mit-green);}
#profile .card-approved p,
#profile .card-approved label{color:#16AB51;}
#profile .card-rejected{border:1px solid red; background-color: #fff8f8;}
#profile .card-rejected h2{color:red;}
#profile .card-rejected p,
#profile .card-rejected label{color:#752f2f;}
#profile label b{font-weight: bold !important;}

/* Formulario de Perfil */
#perfil .profile-section{
	margin-bottom: 40px;
	border-bottom: 1px solid #EDEDED;
}

/* Mensajes Vacíos */
#empty-message p{
	color: var(--mit-gray);
	margin-bottom: 30px;
	font-style: italic;
}


/* Formulario de Abono */
#abono{
	counter-reset: section !important;
	counter-reset: person !important;
	counter-reset: buses !important;
}
#abono h2{
	color: var(--mit-green);
	font-size: 20px;
	 line-height: 36px;
	 vertical-align: middle;
}
#abono .yellow-box{
	display: block;
	background-color: #fff200;
	text-align: center;
	padding: 20px 30px;
	border: 1px solid #c2c2c2;
	color: #000;
	font-size: 15px;
	margin-bottom: 45px;
}
#abono .yellow-box p{ display:block; margin-bottom: 20px;}
#abono .yellow-box p:last-child{ margin-bottom:0px;}
#abono section{display: block; margin-bottom: 45px; font-size: 15px;}
#abono section h2{position: relative; line-height: 30px; vertical-align: middle; margin-bottom: 20px; margin-bottom: 10px;}
#abono section h2 big{
	display: inline-block;
	background-color: var(--mit-green);
	color: #fff;
	width: 30px;
	height: 30px;
	line-height: 30px;
	vertical-align: middle;
	border-radius: 100%;
	text-align: center;
	margin-top: -5px;
	margin-right: 20px;
	font-size: 20px;
}
#abono section .rounded-box{
	display: block;
	border: 1px solid #c2c2c2;
	color: #8f8887;
	border-radius: 10px;
	background-color: #F7F7F7;
	font-size: 15px;
	padding: 40px 20px;
}
#abono section .rounded-box label{font-weight: bold; display: block; height: 48px; line-height: 48px; margin-bottom: 12px;}
#abono section .rounded-box label.has-check::after{top: 14px;}
#abono section .rounded-box label.has-check input[type="checkbox"]:checked::before{top:17px;}
#abono section .rounded-box label input[type="check-box"]{display: inline-block; margin-right: 25px;}
#abono section .rounded-box .packages{margin-top: 30px;}
#abono section .mit-forms{margin-top:20px;}
#abono section .rounded-box h3{color:var(--mit-green); font-size: 16px;}
#abono section .buses-holder{margin-top:30px;}
#abono section .buses-holder .bus::after{
	counter-increment: buses;
	display: inline-block;
	content: "#"counter(buses);
	margin-left: 10px;
}
#abono section .buses-holder .bus{margin-right: 15px;}
#abono section .mit-forms input[type="text"],
#abono section .mit-forms textarea{background-color: #fff; vertical-align: top; margin-bottom:0px;}
#abono section .mit-forms select{text-align: center; background-color: #fff; padding: 0px 0px 0px 18px; width: 100%; height: 44px; vertical-align: top; margin-bottom:0px;}
#abono section .mit-forms .disclaimer{color: red; font-size: 12px; padding: 0px 24px; margin-bottom: 20px;}
#abono section .yellow-ribbon{display: block; position: relative; margin: 20px 0px; height: 60px;}
#abono section .yellow-ribbon .box{
	position: absolute;
	background-color: #fff200;
	border: 1px solid #c2c2c2;
	color: #19a951;
	font-size: 12px;
	padding: 10px 30px;
	left: -12%;
	width: 112%;
}
#abono section .yellow-ribbon .box img{
	display: inline-block;
	position: absolute;
	left: 60%;
	top: 34px;
}
#abono section .split-deposit b{color: var(--mit-green); font-weight: bold;}
#abono section .split-deposit em{display: block;}
#abono section .split-deposit strong{
	display: block;
	color: #696969;
	font-weight: bold;
	font-style: normal;
}
#abono section .split-deposit .td{margin-bottom: 5px;}
#abono section .split-deposit .td b{display: block; height: 44px; line-height: 44px; color: red;}

#abono .resume-box{
	position: relative;
	background-color: #19a952;
	color: #fff;
	border-radius: 10px;
	padding: 70px 10px 40px 10px;
	font-size: 13px;
	z-index: 1;
}
#abono .resume-box.fixed{position: fixed; top: 0px; width:228.984px; z-index: 1;}
#abono .resume-box h2{
	display: block;
	width: 90%;
	color: #fff200;
	font-size: 24px;
	padding: 0px 5%;
	position: absolute;
	top: 0px;
	height: 45px !important;
	line-height: 45px !important;
	background-color:#0d873e;
	border-radius: 10px;
	left: 0px;
}
#abono .resume-box .th{color:#fff200; font-weight: bold; margin-bottom: 12px;}
#abono .resume-box .td{
	min-height: 30px;
	line-height: 18px;
	vertical-align: middle;
	border-bottom: 1px solid #47ba75;
	padding: 5px 0px;
}
#abono .resume-box .td label{display: block; padding-right: 4px;}
#abono .resume-box .td span{color: #fff200;}
#abono .resume-box .td b{color: #aeff00;}
#abono .resume-box .ribbon{height: 120px; margin-top: 30px;}
#abono .resume-box .ribbon .floater{
	position: absolute;
	width:106%;
	left: -3%;
}
#abono .resume-box .ribbon .floater .total{background-color:#0d873e; padding: 10px 20px;}
#abono .resume-box .ribbon .floater .adeudo{background-color:#ff0000; padding: 10px 20px;}
#abono .resume-box .ribbon .floater .adeudo b{color: #fff;}
#abono .resume-box .ribbon .td{border-bottom: none;}
#abono .resume-box .chk{margin-bottom: 30px;}
#abono .resume-box .chk label.has-check span{
	display:inline-block;
	color: #FFF200;
}
#abono .resume-box .chk label.has-check b{margin-bottom: 24px; color: #FFF200; text-decoration: underline; display: inline-block;}
#abono .transBox .camion{zoom:0.58; margin-top: 30px;}



/* Img Loader Holder */
.img-loader-holder{text-align: center; padding: 20px; cursor: pointer; border:1px solid #EDEDED; height: 120px !important; overflow:hidden;}
.img-loader-holder span{
    display: inline-block;
    background-color: #55595c;
    color: #f1f1f1;
    width: 70px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
}
.img-loader-holder span i{
    display: inline-block;
}
.img-loader-holder img{
    display: inline-block;
    width: 100%;
}
.img-loader-holder input[type="file"]{display: none;}


/* Mensajes */
#mensaje{display: block;}
#mensajes nav{
	display: block;
	height: 100vh;
	background-color: #F2F2F2;
}
#mensajes nav h1{
	background-color: var(--mit-dark);
	color: #FFF;
	font-size:18px;
	font-weight: 100;
	margin: 0px;
	padding: 0px 15px;
}
#mensajes nav .aconv{
	display: block;
	line-height: 20px;
	padding:15px;
	color: #111111;
	transition: all 0.35s;
	background-color: #EDEDED;
	font-size:13px;
	font-weight: 100;
	margin-bottom: 2px;
	cursor: pointer;
	position: relative;
}
#mensajes nav .aconv i{margin-right: 5px;}
#mensajes nav .aconv:hover,
#mensajes nav .aconv.active{
	background-color: var(--mit-green);
	color:#fff;
}
#mensajes nav .aconv span{
    display: block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 100%;
    background-color: red;
    color: #FFF;
    font-size: 10px;
    text-align: center;
    font-weight: 100;
    position: absolute;
    top: -9px;
	right: -9px;
	transition: all 0.35s;
}
#mensajes nav .aconv:hover span{
	background-color: var(--mit-yellow);
	color: #111111;
}

#mensajes .chat-tab{
	border:1px solid #EDEDED;
	height: 100vh;
	position: relative;
	overflow-x: hidden;
}
#mensajes .chat-tab .text-area{
	position: absolute;
	bottom: 0px;
	width: 100%;
	height: 130px;
	left: 0px;
}
#mensajes .chat-tab .text-area textarea{
	width: 95%;
	border:none;
	font-family: 'Oxygen', sans-serif;
}
#mensajes .chat-tab .text-area .cta{
	display: inline-block;
	position: absolute;
	bottom: 10px;
	right: 10px;
	z-index: 999;
}
#mensajes .chat-tab .chat{
	height: 80vh;
	max-height: 80vh;
}

/*************************************
	   Responsive Breakpoints
*************************************/
/* XS=> 0px or larger */
@media only screen and (min-width: 0px)  and (max-width:599px){
	.breakpoint{width:320px;}
	h1{text-align: center;}
	.event-item .text{padding: 0px 0px;}
	.event-item, .blog-item{text-align: center;}
	#blog .link-more,
	#blog-post .link-more{
		text-align: center;
		margin-top: 0px;
		margin-bottom: 35px;
	}
	.event-item .button-holder{text-align: center; width: 100%;}
	.event-item .button-holder .cta.gray{display:none;}
	footer{text-align: center;}
	.event-item h2{display:block; margin-bottom: 25px;}
	.event-item label, .event-item b, .event-item span{display:block; margin-bottom: 10px;}
	.event-item span b{display: inline-block;}
    .banner-holder .banner-item .banner-text{
	    position: absolute;
	    width: 90%;
	    margin-left: -45%;
	    text-align: center;
    }
    #wrapper{text-align: center;}
    #contacto .contact-info{text-align: center;}
    .link-more{text-align: center; font-size:12px;}
	#detalle .boxed{text-align: center;}
    /* Profile */
	#profile header{height: 100px;}
	#profile h1{height: auto;}
    #profile main{margin-top: 240px; padding: 0px;}
    #profile #empty-message{text-align: center; margin-bottom: 80px;}
	#profile #perfil{margin-bottom: 50px;}
	#profile #abono{padding-bottom: 40px;}
	#profile #abono .resume-box{margin-top: -30px;}
    #profile #abono h2{height: auto; line-height: 24px; margin-bottom: 15px;}
    #profile #abono h2, #profile #abono h2 + b{display:block; text-align: center !important;}
    #abono h2 + b{margin-bottom: 25px;}
    #profile #abono section h2{font-size: 16px; text-align: left !important;}
    #abono .transBox .camion{zoom:0.55;}
	.cta{padding: 0px 7px;}
    .event-holder .preview{text-align: center;}
    .event-holder .detail .deposits .row{text-align: center;}
    .event-holder .cta.green,.event-holder .cta.gray{margin-bottom: 10px;}
    .banner-holder .banner-item .banner-text h2{font-size: 24px;}
	#blog-post .gray-container span{font-size: 12px;}
	#abono{padding: 0px 20px;}
	#abono section .rounded-box label.cta{line-height: 48px;}
	#profile div p{font-size:11px;}
	#abono section .yellow-ribbon{height: 75px;}
	#abono section .yellow-ribbon .box{width: 90%; padding: 5%; left: 0%;}
	#abono section .yellow-ribbon .box img{width: 30px; top:65px;}
	#abono section .mit-forms{font-size: 11px;}
	#abono section .mit-forms .th{display: none;}
	#abono section .mit-forms input,
	#abono section .mit-forms select,
	#abono section .mit-forms .cta{font-size:11px; height: 30px; line-height: 30px;}
	.header-mobile{width: 100%;}
	.header-mobile .logo{
		width: 80%;
	}
	.header-mobile a.menu-trigger{margin-right: 20px;}
	.header-mobile .user-options big{font-size:18px; line-height: 22px;}
	.carousel .control-arrow{display: none;}
	#mensajes nav{height: auto;}
	.mit-forms .divider::before{
		display: block;
		content: ' ';
		background-color: #EDEDED;
		position: absolute;
		height: 1px;
		width: 100%;
		left: 0px;
		top: 0px;
	}
	#blog-post .gray-container span{font-size: 14px;}
	.cta{
		height: 30px;
		line-height: 30px;
		padding: 0px 10px;
		font-size: 14px;
		position: relative;
		z-index: 1;
	}
	#detalle .boxed .cta{font-size:12px;}
	#detalle .boxed .cta i{font-size: 10px !important;}
	#detalle .boxed .cta.white{font-size: 10px !important;}
	#agenda.destino h3{text-align: center;}
	.event-holder h2{text-align: center;}
	#mensajes .chat-tab{margin-bottom: 80px;}

	#abono section .buses-holder {
		height: 470px;
		max-width: 100%;
	}
	.transBox{
		transform: rotate(90deg);
		height: auto;
		padding: 0px;
		margin-top: 55px;
	}
	.transBox .camion {
		width: 100%;
		height: auto;
		display: inline-block;
		vertical-align: top;
		position: relative;
		zoom: 0.70;
		margin: 0px;
    }
    .whatsapp-plugin{
        bottom: 10px;
        left: 10px;
	}
	#detalle .banner-holder .banner-item .banner-text{
		padding-left: 0px;
		padding-right: 0px;
		top:250px;
	}
	header .tel b{color: #FFF; }
	.header-mobile .follow-us span{
		color: #fff;
	}
	header .follow-us ul li:nth-child(1) a{
		color: #fff;
	}
}

/* SM=> 600px or larger */
@media only screen and (min-width: 600px)  and (max-width:959px){
	.breakpoint{width: 580px;}
	h1{text-align: center;}
	.event-item .text{padding: 0px 0px;}
	.event-item, .blog-item{font-size:14px;}
	#agenda.destino h3{text-align: center;}
	#blog .link-more,
	#blog-post .link-more{
		text-align: center;
		margin-top: 0px;
		margin-bottom: 35px;
	}
    .event-item .button-holder{width: 100%;}
    .event-item .button-holder .cta.gray{display:none;}
    .event-item h2{display:block; margin-bottom: 25px;}
    .banner-holder .banner-item .banner-text{
	    position: absolute;
	    width: 500px;
	    margin-left: -250px;
	    text-align: center;
    }
    #wrapper{text-align: center;}
    #contacto .contact-info{text-align: center;}
    .link-more{text-align: center; font-size: 11px;}
    /* Profile */
    #profile header{height: 100px;}
    #profile main{margin-top: 240px; padding: 0px;}
    #profile #empty-message{text-align: center; margin-bottom: 80px;}
	#profile #perfil{margin-bottom: 50px;}
	#profile #abono{padding-bottom: 40px;}
    #profile #abono .resume-box{margin-top: -30px;}
    #profile #abono h2, #profile #abono h2 + b{display:block; text-align: center !important;}
    #profile #abono h2{height: auto; line-height: 24px; margin-bottom: 15px;}
	#abono h2 + b{margin-bottom: 25px;}
    .event-holder .preview{text-align: center;}
    .event-holder .preview{text-align: center;}
    .event-holder .detail .deposits{
		font-size: 12px;
	}
    .event-holder .cta.green,.event-holder .cta.gray{margin-bottom: 10px;}
    .banner-holder .banner-item .banner-text h2{font-size: 32px;}
    #abono .resume-box.fixed{position: inherit; top: inherit; width:inherit; z-index: 1; border:none !important;}
	#blog-post .gray-container span{font-size: 14px;}
	.cta{
		height: 30px;
		line-height: 30px;
		padding: 0px 10px;
		font-size: 14px;
	}
	#detalle .boxed .cta{font-size:12px;}
	#detalle .boxed .cta i{font-size: 10px !important;}
	#detalle .boxed .cta.white{font-size: 10px !important;}
	.carousel .control-arrow{display: none;}
	.event-item .img-holder{
		min-height: 400px;
	}
	#detalle .banner-holder .banner-item .banner-text{
		padding-left: 0px;
		padding-right: 0px;
		top:250px;
	}
	header .tel b{color: #FFF; }
	.header-mobile .follow-us span{
		color: #fff;
	}
	header .follow-us ul li:nth-child(1) a{
		color: #fff;
	}
}

/* MD=> 960px or larger */
@media only screen and (min-width: 960px) and (max-width:1279px){
	.breakpoint{max-width: 920px;}
	header nav ul li a{font-size: 12px;}
	header .tel{font-size: 0px;}
	header .tel b{font-size: 14px; color: #000;}
    h1{text-align: center;}
    .banner-holder .banner-item .banner-text{
	    position: absolute;
	    width: 700px;
	    margin-left: -350px;
	    text-align: center;
    }
    .event-item, .blog-item{margin-bottom: 30px;}
    #blog .link-more{
    	text-align: center;
    	margin-top: 0px;
    	margin-bottom: 35px;
    }
    .blog-item .img-holder{height: auto;}
    #wrapper{text-align: center;}
    #contacto .contact-info{text-align: center;}
    .event-item h2{display:block; margin-bottom: 25px;}
    .event-item label, .event-item b, .event-item span{margin-bottom: 10px; font-size: 14px;}
    .link-more{text-align: center;}
    /* Profile */
    #profile header{height: 100px;}
    /* #profile main{margin-top: 240px; padding: 0px;} */
    #profile #empty-message{text-align: center; margin-bottom: 80px;}
    #profile #perfil{margin-bottom: 50px;}
    #profile #abono h2, #profile #abono h2 + b{display:block; text-align: center !important;}
    #abono h2 + b{margin-bottom: 25px;}
	#profile #right-nav ul li a{
		font-size:14px;
		padding: 0px;
	}
	.event-holder .detail .deposits{text-align: left;}
	.event-holder .detail .deposits .row{font-size:14px; text-align: left;}
	.cta{font-size: 14px;}
	#profile header.desktop label{font-size: 10px;}
	.header-mobile .follow-us span{
		color: #fff;
	}
}


/* LG=> 1280px or larger */
@media only screen and (min-width: 1280px){
	.breakpoint{max-width: 1140px;}
    header nav ul li a{font-size: 14px;}
    .banner-holder .banner-item .banner-text{
	    position: absolute;
	    width: 960px;
	    margin-left: -480px;
    }
}